<template>
  <div style="padding-bottom: 4.5%">
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">Member Tier Detail</h1>
        </div>
        <div class="title-panel mt-3">Member Tier Info</div>
        <b-container class="no-gutters bg-white">
          <div class="py-3">
            <b-row>
              <b-col>
                <InputText
                  textFloat="Name"
                  placeholder="Name"
                  type="text"
                  name="name"
                  isRequired
                  :isValidate="$v.form.name.$error"
                  :v="$v.form.name"
                  v-model="form.name"
                />
              </b-col>
              <b-col>
                <InputText
                  textFloat="Baht Per Point"
                  placeholder="Baht Per Point"
                  type="number"
                  name="baht_per_point"
                  isRequired
                  :v="$v.form.baht_per_point"
                  v-model="form.baht_per_point"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="label">Entry Point</label>
                <b-form-checkbox
                  class="mt-1"
                  v-model="form.is_entry_point"
                  name="checkbox-1"
                  :value="1"
                  :unchecked-value="0"
                >
                  Entry point
                </b-form-checkbox>
              </b-col>
              <b-col>
                <div class="d-flex align-items-center">
                  <span class="label">Upgrade Tier</span>
                </div>
                <InputSelect
                  name="Upgrade Tier"
                  isRequired
                  v-model="form.upgrade_tier_id"
                  v-bind:options="upgradeTier"
                  valueField="id"
                  textField="name"
                  :noPleaseSelect="noPleaseSelect"
                />
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col>
                <InputText
                  textFloat="Upgrade Baht"
                  placeholder="Upgrade Baht"
                  type="number"
                  name="upgrade_baht"
                  :v="$v.form.upgrade_baht"
                  v-model="form.upgrade_baht"
                  :isValidate="requiredUpgradeBaht ? true : false"
                  :class="requiredUpgradeBaht ? 'mb-0' : ''"
                />
                <div v-if="requiredUpgradeBaht">
                  <span class="text-error">Please Input.</span>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex align-items-center">
                  <span class="label">Downgrade Tier</span>
                </div>
                <InputSelect
                  title=""
                  name="Downgrade Tier"
                  isRequired
                  v-model="form.downgrade_tier_id"
                  v-bind:options="upgradeTier"
                  valueField="id"
                  textField="name"
                  :noPleaseSelect="noPleaseSelect"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <InputText
                  textFloat="Membership Period"
                  placeholder="Membership Period"
                  type="number"
                  name="membership_period"
                  :v="$v.form.membership_period"
                  v-model="form.membership_period"
                  :isValidate="requiredMemberPeriod ? true : false"
                  :class="requiredMaintainBaht ? 'mb-0 mt-3' : ''"
                />
                <div v-if="requiredMemberPeriod">
                  <span class="text-error">Please Input.</span>
                </div>
              </b-col>
              <b-col>
                <InputText
                  textFloat="Maintain Baht"
                  placeholder="Maintain Baht"
                  type="number"
                  name="maintain_baht"
                  :v="$v.form.maintain_baht"
                  v-model="form.maintain_baht"
                  :isValidate="requiredMaintainBaht ? true : false"
                  :class="requiredMaintainBaht ? 'mb-0 mt-3' : ''"
                />
                <div v-if="requiredMaintainBaht">
                  <span class="text-error">Please Input.</span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <UploadFile
                  textFloat="Icon Status"
                  placeholder="Please select a file to upload."
                  format="image"
                  name="thumbnail"
                  :fileName="form.icon"
                  :accept="acceptType"
                  text="*Please upload only file .png, .jpg size 1:1  and less than 10 MB"
                  isRequired
                  v-on:onFileChange="onImageChange"
                  :v="$v.form.icon"
                  :isValidate="$v.form.icon.$error"
                />
              </b-col>
              <b-col>
                <InputText
                  textFloat="Display Name"
                  placeholder="Display Name"
                  type="text"
                  name="display"
                  isRequired
                  :isValidate="$v.form.display.$error"
                  :v="$v.form.display"
                  v-model="form.display"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <div
                  class="preview-box"
                  v-bind:style="{
                    'background-image': 'url(' + showPreview + ')',
                  }"
                >
                  <img
                    src="@/assets/icons/loading.svg"
                    class="loading"
                    alt="loading"
                    v-if="isLoadingImage"
                  />
                  <div class="text-right">
                    <font-awesome-icon
                      icon="times-circle"
                      class="text-secondary delete-icon pointer"
                      v-if="form.icon"
                      @click="onDeleteImage"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-container>
        <FooterAction @submit="saveForm()" routePath="/membertier" />
      </b-form>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import OtherLoading from "@/components/loading/OtherLoading";
import UploadFile from "@/components/inputs/UploadFile";
export default {
  components: {
    InputText,
    InputSelect,
    OtherLoading,
    UploadFile,
  },
  validations: {
    form: {
      name: { required },
      baht_per_point: { required },
      icon: { required },
      display : { required }
    },
  },
  watch: {
    "form.membership_period": function () {
      if (this.form.membership_period) {
        if (this.form.membership_period !== "0") {
          this.requiredMemberPeriod = false;
        }
      } else {
        this.requiredMemberPeriod = true;
      }
    },
    "form.maintain_baht": function () {
      if (this.form.maintain_baht) {
        if (this.form.maintain_baht !== "0") {
          this.requiredMaintainBaht = false;
        }
      } else {
        this.requiredMaintainBaht = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      noPleaseSelect: true,
      form: {
        name: "",
        is_entry_point: 0,
        baht_per_point: 0,
        upgrade_tier_id: 0,
        upgrade_baht: 0,
        downgrade_tier_id: 0,
        membership_period: 0,
        maintain_baht: 0,
        icon: "",
        display : ""
      },
      upgradeTier: [],
      isDisable: true,
      requiredMemberPeriod: false,
      requiredMaintainBaht: false,
      requiredUpgradeBaht: false,
      id: this.$route.params.id,
      acceptType: "image/jpeg, image/png",
      showPreview: "",
      isLoadingImage: false,
    };
  },
  async created() {
    this.isLoading = true;
    await this.getDropdown();
    if (this.id !== "0") {
      await this.getMemberDetail();
    }
  },
  methods: {
    async getDropdown() {
      await this.$store.dispatch("getDropdown");
      const data = this.$store.state.memberTier.dropdown;
      if (data.result == 1) {
        this.upgradeTier = data.detail;
        this.isLoading = false;
      }
    },
    async getMemberDetail() {
      await this.$store.dispatch("getDetailMember", this.id);
      const data = this.$store.state.memberTier.detailMember;
      if (data.result === 1) {
        this.form = data.detail;
        this.showPreview = this.form.icon;
        this.isLoading = false;
      }
    },
    async saveForm() {
      this.$v.form.$touch();
      if (this.form.upgrade_tier_id) {
        if (!this.form.upgrade_baht) {
          this.requiredUpgradeBaht = true;
        } else {
          this.requiredUpgradeBaht = false;
        }
      }
      if (this.form.downgrade_tier_id) {
        if (!this.form.membership_period) {
          this.requiredMemberPeriod = true;
        } else {
          this.requiredMemberPeriod = false;
        }
        if (!this.form.maintain_baht) {
          this.requiredMaintainBaht = true;
        } else {
          this.requiredMaintainBaht = false;
        }
      }
      if (this.$v.form.$error) {
        return;
      }
      this.isLoading = true;
      this.form.upgrade_baht = parseInt(this.form.upgrade_baht);
      this.form.baht_per_point = parseInt(this.form.baht_per_point);
      this.form.membership_period = parseInt(this.form.membership_period);
      this.form.maintain_baht = parseInt(this.form.maintain_baht);
      if (this.id === "0") {
        await this.$store.dispatch("createdMemberTier", this.form);
        const data = this.$store.state.memberTier.createdMemberTier;
        if (data.result === 1) {
          this.$swal("Create Success!", {
            icon: "success",
          }).then(function () {
            window.location.href = "/membertier";
          });
        } else {
          this.isLoading = false;
          this.$swal(data.message, {
            icon: "warning",
          });
        }
      } else {
        let reqBody = {
          form: this.form,
          id: this.id,
        };
        await this.$store.dispatch("updateMemberTier", reqBody);
        const data = this.$store.state.memberTier.updateMember;
        if (data.result === 1) {
          this.$swal("Update Success!", {
            icon: "success",
          }).then(function () {
            window.location.href = "/membertier";
          });
        } else {
          this.isLoading = false;
          this.$swal(data.message, {
            icon: "warning",
          });
        }
      }
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.form.icon = this.images;
        this.showPreview = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      }
    },
    onDeleteImage() {
      this.form.icon = "";
      this.showPreview = "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-select {
  color: #212529;
  border: 1px solid #bcbcbc;
  border-radius: 0px;
  padding: 5px 10px;
  height: 31px;
}
.label {
  color: #212529;
  font-size: 17px;
  margin-bottom: 2px;
  font-weight: bold;
}
.img-box-preview {
  width: 100%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
</style>
